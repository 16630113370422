require('isomorphic-fetch');
const xmldoc = require('xmldoc');
import * as xmldocTypes from 'xmldoc';
import { Correction } from './Correction';
import { MissingResponseValueError } from './errors';

export const requests = {
  autoCorrect: (text: string) =>
    `
      <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:v3="http://www.prolexis.com/ProLexisService/v3">
        <soapenv:Header/>
        <soapenv:Body>
          <v3:autoCorrect>
            <autoCorrectionInput>
              <text><![CDATA[${text}]]></text>
            </autoCorrectionInput>
          </v3:autoCorrect>
        </soapenv:Body>
      </soapenv:Envelope>
  `.replace(/>\n( )*/g, '>'),
  startCorrection: (text: string, sessionId?: number) =>
    `
    <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:v3="http://www.prolexis.com/ProLexisService/v3">
      <soapenv:Header/>
      <soapenv:Body>
        <v3:analyze>
          <analyzerInput>
            ${sessionId && `<correctionSessionId>${sessionId}</correctionSessionId>`}
            <text><![CDATA[${text}]]></text>
          </analyzerInput>
        </v3:analyze>
      </soapenv:Body>
    </soapenv:Envelope>
  `.replace(/>\n( )*/g, '>'),
  correct: (
    correctionSessionId: number,
    errorIndex: number,
    correction: string,
    multiple: boolean = false,
  ) =>
    `
    <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:v3="http://www.prolexis.com/ProLexisService/v3">
      <soapenv:Header/>
      <soapenv:Body>
        <v3:correct>
          <correctionSessionId>${correctionSessionId}</correctionSessionId>
          <errorId>${errorIndex}</errorId>
          <correction><![CDATA[${correction}]]></correction>
          <multiple>${multiple}</multiple>
        </v3:correct>
      </soapenv:Body>
    </soapenv:Envelope>
  `.replace(/>\n( )*/g, '>'),
  ignore: (correctionSessionId: number, errorIndex: number, multiple: boolean = false) =>
    `
    <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:v3="http://www.prolexis.com/ProLexisService/v3">
      <soapenv:Header/>
      <soapenv:Body>
      <v3:ignore>
        <correctionSessionId>${correctionSessionId}</correctionSessionId>
        <errorId>${errorIndex}</errorId>
        <multiple>${multiple}</multiple>
      </v3:ignore>
      </soapenv:Body>
    </soapenv:Envelope>`.replace(/>\n( )*/g, '>'),
  explanation: (correctionSessionId: number, errorIndex: number) =>
    `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:v3="http://www.prolexis.com/ProLexisService/v3">
       <soapenv:Header/>
       <soapenv:Body>
         <v3:explanation>
           <correctionSessionId>${correctionSessionId}</correctionSessionId>
           <errorId>${errorIndex}</errorId>
         </v3:explanation>
      </soapenv:Body>
    </soapenv:Envelope>`.replace(/>\n( )*/g, '>'),
};

export type ErrorLocation = {
  offset: number;
  length: number;
};

export enum ErrorTypeEnum {
  orthographe = '1',
  grammaire = '2',
  typography = '3',
  contexte = '4',
  frequence = '5',
  presse = '6',
}

export enum ErrorStatusEnum {
  pending = '0',
  corrected = '1',
  ignored = '2',
}

export type ProlexisError = {
  type: ErrorTypeEnum;
  status: ErrorStatusEnum;
  location: ErrorLocation;
  contextLocation: ErrorLocation;
  duplicateErrorCount: number;
  duplicateNextErrorId: number;
  label: string;
  diagnosis: string;
  corrections: string[];
};

export type AnalyzerOutput = {
  correctionSessionId: number;
  currentErrorIndex: number;
  errors: ProlexisError[];
};

export type AutoCorrectionOutput = {
  correctionCount: number;
  corrections: Correction[];
};

export type CorrectOutput = {
  selectedError: number;
  textCheckSum: string;
  errors: ProlexisError[];
};

export type ExplanationOutput = {
  html: string;
};

class ProlexisClient {
  private url: string;
  private apiKey?: string;
  constructor(serverName: string, apiKey?: string) {
    this.url = `${serverName}/prolexisws/v3/ProLexisService`;
    this.apiKey = apiKey;
  }

  private post = async (request: string) => {
    const response = await fetch(this.url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      headers: {
        'Content-Type': 'text/xml;charset=UTF-8',
        ...(this.apiKey ? { 'X-PLWs-Key': this.apiKey } : {}),
      },
      body: request, // body data type must match "Content-Type" header
    });
    const textResponse = await response.text();
    return new xmldoc.XmlDocument(textResponse);
  };

  private valueExtractorFunction = (requestName: string, response: xmldocTypes.XmlElement) => (
    node: xmldocTypes.XmlElement,
    path: string,
    fullPath?: string,
    throwOnMissing = true,
  ) => {
    const value = node.valueWithPath(path);
    if (!value && throwOnMissing) {
      throw new MissingResponseValueError(requestName, fullPath || path, response);
    }
    return value || '';
  };

  private extractErrors = (
    document: xmldocTypes.XmlElement,
    extractValue: Function,
    errorsPath: string,
  ): ProlexisError[] => {
    const errors = document.descendantWithPath(errorsPath);
    if (!errors) {
      throw new MissingResponseValueError('analyze', errorsPath, document);
    }
    const paths = {
      corrections: 'data.corrections',
      label: 'data.label',
      diagnosis: 'data.diagnosis',
      location: {
        length: 'header.location.length',
        offset: 'header.location.offset',
      },
      contextLocation: {
        length: 'header.contextLocation.length',
        offset: 'header.contextLocation.offset',
      },
      type: 'header.type',
      status: 'header.status',
      duplicateErrorCount: 'header.duplicateErrorCount',
      duplicateNextErrorId: 'header.duplicateNextErrorId',
    };

    return errors.children.map((errorNode, index) => {
      const errorXmlElement: xmldocTypes.XmlElement = errorNode as xmldocTypes.XmlElement;
      let corrections: string[] = [];
      const correctionNode = errorXmlElement.descendantWithPath(paths.corrections);
      if (correctionNode) {
        corrections = correctionNode.children.map(correctionNode => {
          const textNode = (correctionNode as xmldocTypes.XmlElement).firstChild;
          // in case of a suppression suggestion there is no child textNode
          // so we return empty string
          return textNode ? (textNode as xmldocTypes.XmlTextNode).text : '';
        });
      }

      return {
        type: extractValue(
          errorXmlElement,
          paths.type,
          `${errorsPath}.${index}.${paths.type}`,
        ) as ErrorTypeEnum,
        status: extractValue(
          errorXmlElement,
          paths.status,
          `${errorsPath}.${index}.${paths.status}`,
        ) as ErrorStatusEnum,
        location: {
          length: Number(
            extractValue(
              errorXmlElement,
              paths.location.length,
              `${errorsPath}.${index}.${paths.location.length}`,
            ),
          ),
          offset: Number(
            extractValue(
              errorXmlElement,
              paths.location.offset,
              `${errorsPath}.${index}.${paths.location.offset}`,
            ),
          ),
        },
        contextLocation: {
          length: Number(
            extractValue(
              errorXmlElement,
              paths.contextLocation.length,
              `${errorsPath}.${index}.${paths.contextLocation.length}`,
            ),
          ),
          offset: Number(
            extractValue(
              errorXmlElement,
              paths.contextLocation.offset,
              `${errorsPath}.${index}.${paths.contextLocation.offset}`,
            ),
          ),
        },
        duplicateErrorCount: Number(
          extractValue(
            errorXmlElement,
            paths.duplicateErrorCount,
            `${errorsPath}.${index}.${paths.duplicateErrorCount}`,
          ),
        ),
        duplicateNextErrorId: Number(
          extractValue(
            errorXmlElement,
            paths.duplicateNextErrorId,
            `${errorsPath}.${index}.${paths.duplicateNextErrorId}`,
          ),
        ),
        label: extractValue(
          errorXmlElement,
          paths.label,
          `${errorsPath}.${index}.${paths.label}`,
          false,
        ),
        diagnosis: extractValue(
          errorXmlElement,
          paths.diagnosis,
          `${errorsPath}.${index}.${paths.diagnosis}`,
        ),
        corrections,
      };
    });
  };

  autoCorrect = async (text: string): Promise<AutoCorrectionOutput> => {
    const request = requests.autoCorrect(text);
    const xmlResponse = await this.post(request);
    const extractValue = this.valueExtractorFunction('autoCorrect', xmlResponse);
    const correctionsXml = xmlResponse.descendantWithPath(
      'soap:Body.ns2:autoCorrectResponse.autoCorrectionOutput.corrections',
    );
    const output: AutoCorrectionOutput = {
      correctionCount: Number(
        extractValue(
          xmlResponse,
          'soap:Body.ns2:autoCorrectResponse.autoCorrectionOutput.correctionCount',
        ),
      ),
      corrections: correctionsXml
        ? correctionsXml.children.map((correction: xmldocTypes.XmlElement) => ({
            length: Number(extractValue(correction, 'location.length')),
            offset: Number(extractValue(correction, 'location.offset')),
            value: extractValue(correction, 'correction'),
          }))
        : [],
    };
    return output;
  };

  startCorrection = async (text: string, sessionId?: number): Promise<AnalyzerOutput> => {
    const request = requests.startCorrection(text, sessionId);
    const xmlResponse = await this.post(request);
    const extractValue = this.valueExtractorFunction('analyze', xmlResponse);

    const paths = {
      errorsList: 'soap:Body.ns2:analyzeResponse.analyzerOutput.errors',
      sessionId: 'soap:Body.ns2:analyzeResponse.analyzerOutput.correctionSessionId',
      currentErrorIndex: 'soap:Body.ns2:analyzeResponse.analyzerOutput.currentErrorIndex',
    };

    const output: AnalyzerOutput = {
      correctionSessionId: Number(extractValue(xmlResponse, paths.sessionId)),
      currentErrorIndex: Number(extractValue(xmlResponse, paths.currentErrorIndex)),
      errors: this.extractErrors(xmlResponse, extractValue, paths.errorsList),
    };
    return output;
  };

  correct = async (
    correctionSessionId: number,
    errorIndex: number,
    correction: string,
    multiple: boolean = false,
  ): Promise<CorrectOutput> => {
    const request = requests.correct(correctionSessionId, errorIndex, correction, multiple);
    const xmlResponse = await this.post(request);
    const extractValue = this.valueExtractorFunction('correct', xmlResponse);

    const paths = {
      errorsList: 'soap:Body.ns2:correctResponse.return.errors',
      selectedError: 'soap:Body.ns2:correctResponse.return.selectedError',
      textCheckSum: 'soap:Body.ns2:correctResponse.return.textCheckSum',
    };

    return {
      errors: this.extractErrors(xmlResponse, extractValue, paths.errorsList),
      selectedError: Number(extractValue(xmlResponse, paths.selectedError)),
      textCheckSum: extractValue(xmlResponse, paths.textCheckSum),
    };
  };

  ignore = async (
    correctionSessionId: number,
    errorIndex: number,
    multiple: boolean = false,
  ): Promise<CorrectOutput> => {
    const request = requests.ignore(correctionSessionId, errorIndex, multiple);
    const xmlResponse = await this.post(request);
    const extractValue = this.valueExtractorFunction('ignore', xmlResponse);

    const paths = {
      errorsList: 'soap:Body.ns2:ignoreResponse.return.errors',
      selectedError: 'soap:Body.ns2:ignoreResponse.return.selectedError',
      textCheckSum: 'soap:Body.ns2:ignoreResponse.return.textCheckSum',
    };

    return {
      errors: this.extractErrors(xmlResponse, extractValue, paths.errorsList),
      selectedError: Number(extractValue(xmlResponse, paths.selectedError)),
      textCheckSum: extractValue(xmlResponse, paths.textCheckSum),
    };
  };

  explanation = async (
    correctionSessionId: number,
    errorIndex: number,
  ): Promise<ExplanationOutput> => {
    const request = requests.explanation(correctionSessionId, errorIndex);
    const xmlResponse = await this.post(request);
    const extractValue = this.valueExtractorFunction('explication', xmlResponse);

    const paths = {
      html: 'soap:Body.ns2:explanationResponse.return',
    };

    return {
      html: extractValue(xmlResponse, paths.html),
    };
  };
}

export default ProlexisClient;
