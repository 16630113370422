import { Modifier, ContentState } from 'draft-js';
import { getSelectionState } from './getSelectionState';
import { Correction } from './Correction';

export const applyCorrection = (
  content: ContentState,
  correction: Correction,
): ContentState | false => {
  const blocks = content.getBlocksAsArray();
  const selection = getSelectionState(blocks, correction);
  if (selection === false) {
    return false;
  }

  const anchorKey = selection.getAnchorKey();
  const currentContentBlock = content.getBlockForKey(anchorKey);
  const offset = selection.getStartOffset();

  const hasEntity = currentContentBlock.getEntityAt(offset);

  return Modifier.replaceText(
    content,
    selection,
    correction.value,
    currentContentBlock.getInlineStyleAt(offset),
    hasEntity ? hasEntity : undefined,
  );
};
