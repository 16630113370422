type TransformationName = 'scale' | 'pad' | 'fit';

type TransformationParameters = {
  quality?: number;
} & {
  [key: string]: string | number | undefined;
};

type TransformationFormat = 'jpeg' | 'jpg' | 'png' | 'gif';

type Config = {
  protocol: 'http' | 'https' | false;
  domain: string;
  defaultTransformation?: TransformationName;
  defaultParameters?: TransformationParameters;
  defaultTitle?: string;
  defaultFormat?: TransformationFormat;
};

type CommonTransformationConfig = {
  origin: string;
  parameters?: TransformationParameters;
  title?: string;
  format?: TransformationFormat;
};

type FitTransformationConfig = {
  width: number;
  height: number;
} & CommonTransformationConfig;

type PadTransformationConfig = FitTransformationConfig;

type ScaleByHeightConfig = { height: number; width: 'auto' } & CommonTransformationConfig;
type ScaleByWidthConfig = { width: number; height: 'auto' } & CommonTransformationConfig;

type ScaleTransformationConfig = ScaleByHeightConfig | ScaleByWidthConfig;

type Transformation = {
  transformation: TransformationName;
} & (FitTransformationConfig | PadTransformationConfig | ScaleTransformationConfig);

type TransformationResult = string;

export default class Client {
  config: { template: string } & Config;

  constructor(config: Config) {
    const template = '/{transformation}/{origin}/{width}x{height}/{parameters}/{title}.{format}';
    const defaultTransformation = 'fit';
    const defaultParameters = { quality: 80 };
    const defaultTitle = 'transformation';
    const defaultFormat = 'jpg';

    this.config = {
      template,
      defaultTransformation,
      defaultParameters,
      defaultTitle,
      defaultFormat,
      ...config,
    };
  }

  static encodeOrigin(origin: string) {
    return encodeURIComponent(origin)
      .replace(/!/g, '%21')
      .replace(/'/g, '%27')
      .replace(/\(/g, '%28')
      .replace(/\)/g, '%29')
      .replace(/\*/g, '%2A')
      .replace(/%20/g, '+')
      .replace(/\./g, '%2E')
      .replace(/%/g, '.');
  }

  transform({
    transformation,
    origin,
    width,
    height,
    parameters,
    title,
    format,
  }: Transformation): TransformationResult {
    const protocol = this.config.protocol ? `${this.config.protocol}:` : '';
    const domain = this.config.domain;
    const mergedParameters: TransformationParameters = {
      ...this.config.defaultParameters,
      ...parameters,
    };

    const cleanSizeParam = (value?: string | number | null): string => {
      let cleanValue = value === 'auto' ? 'auto' : '';
      if (value && typeof value === 'string' && value !== 'auto') {
        cleanValue = parseInt(value, 10).toString();
      }
      if (value && typeof value === 'number') {
        cleanValue = value.toString();
      }
      return cleanValue;
    };

    const cleanWidth = cleanSizeParam(width);
    const cleanHeight = cleanSizeParam(height);

    const path = this.config.template
      .replace('{transformation}', transformation || this.config.defaultTransformation)
      .replace('{origin}', Client.encodeOrigin(origin))
      .replace('{width}', cleanWidth)
      .replace('{height}', cleanHeight)
      .replace(
        '{parameters}',
        Object.keys(mergedParameters)
          .map(parameterName => `${parameterName}/${mergedParameters[parameterName]}`)
          .join('/'),
      )
      .replace('{title}', title || (this.config.defaultTitle as string))
      .replace('{format}', format || (this.config.defaultFormat as string));

    return `${protocol}//${domain}${path}`;
  }

  fit(config: FitTransformationConfig): TransformationResult {
    return this.transform({ transformation: 'fit', ...config });
  }

  pad(config: PadTransformationConfig): TransformationResult {
    return this.transform({ transformation: 'pad', ...config });
  }

  scale(config: ScaleTransformationConfig): TransformationResult {
    return this.transform({ transformation: 'scale', ...config });
  }
}
