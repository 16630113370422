import { SelectionState, ContentBlock } from 'draft-js';
import { getBlockBoundaries } from './getBlockBoundaries';

export type Range = {
  offset: number;
  length: number;
};

export const getSelectionState = (blocks: ContentBlock[], range: Range): SelectionState | false => {
  const blockBoundaries = getBlockBoundaries(blocks);
  const startBlock = blockBoundaries.find(
    boundary => range.offset >= boundary.start && range.offset <= boundary.end,
  );
  if (!startBlock) {
    return false;
  }
  const selectionState = SelectionState.createEmpty(startBlock.block.getKey());
  const anchorOffset = range.offset - startBlock.start;

  const endBlock = blockBoundaries.find(
    boundary =>
      range.offset + range.length >= boundary.start && range.offset + range.length <= boundary.end,
  );

  if (!endBlock) {
    return false;
  }

  const focusOffset = range.offset + range.length - endBlock.start;

  return selectionState.merge({
    anchorKey: startBlock.block.getKey(),
    anchorOffset,
    focusKey: endBlock.block.getKey(),
    focusOffset,
  }) as SelectionState;
};
