import { EditorState } from 'draft-js';
import ProlexisClient, { ProlexisError } from './ProlexisClient';
import { applyCorrection } from './applyCorrection';
import { Correction } from './Correction';
import {
  RestartingCorrectionSessionError,
  CorrectionSessionNotStarted,
  CantApplyCorrectionError,
  OutOfBoundError,
  MultipleCorrectionInfiniteLoop,
  EmptyText,
  DesynchronizationProlexis,
} from './errors';
import * as md5Proxy from 'md5';
// work around rollup type checking
const md5 = (md5Proxy as any).default || md5Proxy;

export class CorrectionSession {
  private client: ProlexisClient;
  private editorState: EditorState;
  private errors: ProlexisError[];
  private correctionSessionId: number | null;
  private currentErrorIndex: number | null;

  constructor(client: ProlexisClient, editorState: EditorState) {
    this.client = client;
    this.editorState = editorState;
    this.errors = [];
    this.correctionSessionId = null;
    this.currentErrorIndex = null;
  }

  private isStarted = () => this.correctionSessionId !== null;

  private checkIsActive = () => {
    if (!this.isStarted()) {
      throw new CorrectionSessionNotStarted();
    }
    if (this.currentErrorIndex === null || this.currentErrorIndex >= this.errors.length) {
      throw new OutOfBoundError();
    }
  };

  getErrors = (): ProlexisError[] => this.errors;
  getCurrentError = (): ProlexisError => {
    if (this.currentErrorIndex === null) {
      throw new CorrectionSessionNotStarted();
    }
    return this.errors[this.currentErrorIndex];
  };

  start = async () => {
    if (this.isStarted()) {
      throw new RestartingCorrectionSessionError();
    }
    // prolexis use \r\n for line return
    const plainText = this.editorState.getCurrentContent().getPlainText('\r\n');
    if (plainText === '') {
      throw new EmptyText();
    }
    const analyze = await this.client.startCorrection(plainText);

    this.errors = analyze.errors;
    this.correctionSessionId = analyze.correctionSessionId;
    this.currentErrorIndex = analyze.currentErrorIndex;

    return this.errors;
  };

  correct = async (correctionText: string, errorIndex?: number): Promise<EditorState> => {
    this.checkIsActive();

    const errorToCorrectIndex = errorIndex === undefined ? this.currentErrorIndex : errorIndex;
    const errorToCorrect = this.errors[errorToCorrectIndex!];

    const response = await this.client.correct(
      this.correctionSessionId as number,
      errorToCorrectIndex!,
      correctionText,
    );
    const correction: Correction = {
      value: correctionText,
      ...errorToCorrect.location,
    };
    const newContentState = applyCorrection(this.editorState.getCurrentContent(), correction);
    if (newContentState === false) {
      throw new CantApplyCorrectionError();
    }

    const md5Sum = md5(newContentState.getPlainText());
    if (md5Sum.toUpperCase() !== response.textCheckSum.toUpperCase()) {
      throw new DesynchronizationProlexis();
    }

    this.editorState = EditorState.push(this.editorState, newContentState, 'insert-characters');
    this.errors = response.errors;
    this.currentErrorIndex = response.selectedError;

    return this.editorState;
  };

  correctMultiple = async (correctionText: string, errorIndex?: number): Promise<EditorState> => {
    this.checkIsActive();

    const errorToCorrectIndex = errorIndex === undefined ? this.currentErrorIndex : errorIndex;
    const errorToCorrect = this.errors[errorToCorrectIndex!];

    const response = await this.client.correct(
      this.correctionSessionId as number,
      errorToCorrectIndex!,
      correctionText,
      true,
    );

    let newContentState = this.editorState.getCurrentContent();
    let currentErrorInCorrection = errorToCorrect;
    let offsetCorrection = 0;
    const correctionDiff = correctionText.length - errorToCorrect.location.length;
    const maxLoop = 1000;
    let loop = 0;
    let firstOffset = errorToCorrect.location.offset;

    do {
      // if true then we looped through the text and came back to the beginning
      // we then must reset offsetCorrection to 0 as followed errors are not impacted by corrections that come after.
      if (currentErrorInCorrection.location.offset < firstOffset) {
        offsetCorrection = 0;
        firstOffset = currentErrorInCorrection.location.offset;
      }
      newContentState =
        applyCorrection(newContentState, {
          value: correctionText,
          offset: currentErrorInCorrection.location.offset + offsetCorrection,
          length: currentErrorInCorrection.location.length,
        }) || newContentState;

      currentErrorInCorrection = this.errors[currentErrorInCorrection.duplicateNextErrorId];
      offsetCorrection += correctionDiff;
      loop++;
    } while (currentErrorInCorrection !== errorToCorrect && loop < maxLoop);

    if (loop === maxLoop) {
      throw new MultipleCorrectionInfiniteLoop();
    }

    const md5Sum = md5(newContentState.getPlainText());
    if (md5Sum.toUpperCase() !== response.textCheckSum.toUpperCase()) {
      throw new DesynchronizationProlexis();
    }

    this.editorState = EditorState.push(this.editorState, newContentState, 'insert-characters');
    this.errors = response.errors;
    this.currentErrorIndex = response.selectedError;

    return this.editorState;
  };

  ignore = async (errorIndex?: number, multiple: boolean = false): Promise<EditorState> => {
    if (!this.isStarted()) {
      throw new CorrectionSessionNotStarted();
    }

    const errorToCorrectIndex = errorIndex === undefined ? this.currentErrorIndex : errorIndex;

    const response = await this.client.ignore(
      this.correctionSessionId as number,
      errorToCorrectIndex as number,
      multiple,
    );
    this.errors = response.errors;
    this.currentErrorIndex = response.selectedError;

    return this.editorState;
  };

  getExplanation = async (errorIndex?: number): Promise<string> => {
    if (!this.isStarted()) {
      throw new CorrectionSessionNotStarted();
    }

    const errorToCorrectIndex = errorIndex === undefined ? this.currentErrorIndex : errorIndex;
    const response = await this.client.explanation(
      this.correctionSessionId as number,
      errorToCorrectIndex as number,
    );

    return response.html;
  };
}
