import { ContentBlock } from 'draft-js';

export type BlockBoundary = {
  start: number;
  end: number;
  block: ContentBlock;
};
export const getBlockBoundaries = (blocks: ContentBlock[]): BlockBoundary[] => {
  let totalLength = 0;
  return blocks.map(block => {
    // -1 because 0 indexed, +1 to account for line return char between blocks
    //  (which is \r\n for prolexis but count as 1 char)
    const blockEnd = block.getText().length + totalLength - 1 + 1;
    const boundary = { start: totalLength, end: blockEnd, block };
    totalLength = blockEnd + 1;
    return boundary;
  });
};
