import { EditorState } from 'draft-js';
import ProlexisClient from './ProlexisClient';
import { applyCorrection } from './applyCorrection';
import { CorrectionSession } from './CorrectionSession';

class DraftjsProlexis {
  private client: ProlexisClient;
  constructor(client: ProlexisClient) {
    this.client = client;
  }

  autoCorrect = async (editor: EditorState): Promise<EditorState> => {
    const autoCorrect = await this.client.autoCorrect(editor.getCurrentContent().getPlainText());

    let content = editor.getCurrentContent();

    autoCorrect.corrections.forEach(correction => {
      const correctionResult = applyCorrection(content, correction);
      if (correctionResult !== false) {
        content = correctionResult;
      }
    });

    return EditorState.push(editor, content, 'insert-characters');
  };

  startCorrectionSession = async (editorState: EditorState): Promise<CorrectionSession> => {
    const correctionSession = new CorrectionSession(this.client, editorState);

    await correctionSession.start();

    return correctionSession;
  };
}

export default DraftjsProlexis;
